import ReportByEmployeesPage from '../pages/reportByEmployeesPage/ReportByEmployeesPage';
import ImportPage from '../pages/importPage/ImportPage';
import ExportPage from '../pages/exportPage/ExportPage';
import { RouteProps } from 'react-router';
import ReportByIdPage from '../pages/reportByIdPage/ReportByIdPage';
import MyActivitiesPage from '../pages/myActivitiesPage/MyActivitiesPage';
import { DashUserRole, RolesRestriction } from '../layout/restricted/Restricted';
import RootPage from '../pages/rootPage/RootPage';
import { ComponentType } from 'react';
import ReportByProjectsPage from '../pages/reportByProjectsPage/ReportByProjectsPage';
import IssueTemplatePage from '../pages/issueTemplatePage/IssueTemplatePage';
import JiraPasswordResetPage from '../pages/jiraPasswordResetPage/JiraPasswordResetPage';

export interface AppRoute extends RouteProps {
    path: string;
    component: ComponentType<any>;
    roles?: RolesRestriction;
}

const routes = {
    root: {
        path: '/',
        component: RootPage
    },
    reportByEmployees: {
        path: '/report-by-employees',
        component: ReportByEmployeesPage,
        roles: DashUserRole.ADMIN
    },
    reportByProjects: {
        path: '/report-by-projects',
        component: ReportByProjectsPage,
        roles: DashUserRole.ADMIN
    },
    import: {
        path: '/import',
        component: ImportPage,
        roles: DashUserRole.USER
    },
    export: {
        path: '/export',
        component: ExportPage,
        roles: DashUserRole.ADMIN
    },
    issueTemplate: {
        path: '/issue-template',
        component: IssueTemplatePage,
        roles: DashUserRole.ADMIN
    },
    jiraPasswordReset: {
        path: '/jira/reset-password',
        component: JiraPasswordResetPage,
        roles: DashUserRole.USER
    },
    reportById: {
        path: '/reports/:reportId',
        component: ReportByIdPage,
        roles: DashUserRole.ADMIN
    },
    activities: {
        path: '/activities',
        component: MyActivitiesPage,
        roles: DashUserRole.USER
    }
} as const;

export const routeList: AppRoute[] = Object.values(routes);

export default routes;
