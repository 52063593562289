import React, { useMemo } from 'react';
import useAuthSelector from '../../hooks/useAuthSelector';
import { UserInfo } from '../../types/apiDomain';
import { DashUserRole, userHasRoles } from '../../layout/restricted/Restricted';
import routes, { AppRoute } from '../../constants/routes';

export const getRootRoute = (user?: UserInfo): AppRoute | undefined => {
    if (!user) {
        return undefined;
    }

    if (userHasRoles(user, DashUserRole.ADMIN)) {
        return routes.reportByEmployees;
    }

    if (userHasRoles(user, DashUserRole.USER)) {
        return routes.activities;
    }
};

const RootPage = () => {
    const { user } = useAuthSelector();
    const route = useMemo(() => getRootRoute(user), [user]);

    if (user === undefined) {
        return null;
    }

    if (route) {
        const RouteComponent = route.component;
        return <RouteComponent />;
    }

    return <h1>Доступ запрещен</h1>;
};

export default RootPage;
