import React, { FunctionComponent, useCallback, useState } from 'react';
import AppPage from '../../layout/appPage/AppPage';
import { useAppDispatch, useAppSelector } from '../../hooks/reactRedux';
import useDataFetching from '../../hooks/useDataFetching';
import getIssueTemplates from '../../actions/api/issueTemplate/getIssueTemplates';
import Status from '../../components/status/Status';
import { Button, Form, Input, Modal, Spin, Tag, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import IssueTemplateSelect from '../../components/issueTemplateSelect/IssueTemplateSelect';
import { ANTD_COLS } from '../../constants/markup';
import TextArea from 'antd/lib/input/TextArea';
import styles from './IssueTemplatePage.module.css';
import { SelectValue } from 'antd/lib/select';
import {
    CreateIssueByTemplateRequest,
    Credentials,
    IssueTemplateEdit,
    IssueTemplateEntity
} from '../../types/apiDomain';
import classNames from 'classnames';
import displayErrorNotification from '../../utils/ui/displayErrorNotification';
import getErrorMessage from '../../utils/getErrorMessage';
import deleteIssueTemplate from '../../actions/api/issueTemplate/deleteIssueTemplate';
import createIssueFromTemplate from '../../actions/api/issueTemplate/createIssueFromTemplate';
import editIssueTemplate from '../../actions/api/issueTemplate/editIssueTemplate';
import createIssueTemplate from '../../actions/api/issueTemplate/createIssueTemplate';
import confirm from 'antd/lib/modal/confirm';
import appNotification from '../../utils/ui/appNotification';
import { entries, has, keys, map } from 'lodash';
import getVariablesInfo from '../../actions/api/issueTemplate/getVariablesInfo';

const labelCol = 7;
const maxCol = ANTD_COLS;

const variablePrefix = 'VAR__';

interface Props {}

interface InternalProps extends Props, FormComponentProps {}

const IssueTemplatePage: FunctionComponent<InternalProps> = ({ form }) => {
    const dispatch = useAppDispatch();

    const issueTemplates = useAppSelector((state) => state.issueTemplates.list);
    const variablesInfo = useAppSelector((state) => state.issueTemplates.variablesInfo);
    const { isLoading, error } = useDataFetching(issueTemplates, getIssueTemplates());

    const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
    const [isDeletionLoading, setIsDeletionLoading] = useState<boolean>(false);
    const [isCreationLoading, setIsCreationLoading] = useState<boolean>(false);
    const [isVariablesInfoLoading, setIsVariablesInfoLoading] = useState<boolean>(false);

    const [selectedTemplate, setSelectedTemplate] = useState<IssueTemplateEntity>();

    const [isVariablesInfoModalVisible, setIsVariablesInfoModalVisible] = useState<boolean>(false);

    const getTemplateEdit = (): IssueTemplateEdit => {
        const { templateContent, name } = form.getFieldsValue();

        return {
            name: name || null,
            template: JSON.parse(templateContent)
        };
    };

    const getCredentials = (): Credentials => form.getFieldsValue(['login', 'password']) as Credentials;

    const getIssueCreationBody = (): CreateIssueByTemplateRequest => {
        const formValues = form.getFieldsValue();

        return {
            credentials: getCredentials(),
            variables: keys(formValues)
                .filter((key) => key.startsWith(variablePrefix))
                .reduce((result, key) => {
                    const variableName = key.replace(new RegExp(variablePrefix + '(.*)'), '$1');
                    result[variableName] = formValues[key];
                    return result;
                }, {})
        };
    };

    const handleSelectedTemplateChange = useCallback(
        (value: SelectValue) => {
            if (!issueTemplates || (typeof value !== 'string' && typeof value !== 'undefined')) {
                return;
            }

            if (value === undefined) {
                setSelectedTemplate(undefined);
                form.setFieldsValue({
                    ...form.getFieldsValue(),
                    templateContent: '',
                    name: ''
                });
                return;
            }

            const template = issueTemplates.find((template) => template.id === value);

            if (!template) {
                throw new Error('Template is undefined');
            }

            setSelectedTemplate(template);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                templateContent: template.template,
                name: template.name
            });
        },
        [form, issueTemplates]
    );

    const handleSaveNewTemplateClick = async () => {
        try {
            setIsSavingLoading(true);
            const createdTemplate = await dispatch(createIssueTemplate(getTemplateEdit()));

            appNotification.success({
                message: 'Шаблон создан'
            });

            setSelectedTemplate(createdTemplate);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                selectedTemplate: createdTemplate.id
            });
        } catch (error) {
            displayErrorNotification(getErrorMessage(error), 'Произошла ошибка при сохранении');
        } finally {
            setIsSavingLoading(false);
        }
    };

    const handleSaveTemplateClick = async () => {
        if (!selectedTemplate) {
            return;
        }

        try {
            setIsSavingLoading(true);
            const edited = await dispatch(editIssueTemplate(selectedTemplate.id, getTemplateEdit()));
            setSelectedTemplate(edited);

            appNotification.success({
                message: 'Шаблон сохранен'
            });
        } catch (error) {
            displayErrorNotification(getErrorMessage(error), 'Произошла ошибка при сохранении');
        } finally {
            setIsSavingLoading(false);
        }
    };

    const handleDeleteTemplateClick = async () => {
        confirm({
            title: 'Удалить выбранный шаблон?',
            okText: 'Удалить',
            okType: 'danger',
            cancelText: 'Отмена',
            onOk: async () => {
                if (!selectedTemplate) {
                    return;
                }

                try {
                    setIsDeletionLoading(true);
                    await dispatch(deleteIssueTemplate(selectedTemplate.id));

                    appNotification.success({
                        message: 'Шаблон удален'
                    });

                    setSelectedTemplate(undefined);
                    form.resetFields(['selectedTemplate', 'templateContent']);
                } catch (error) {
                    displayErrorNotification(getErrorMessage(error), 'Произошла ошибка при удалении');
                } finally {
                    setIsDeletionLoading(false);
                }
            },
            centered: true
        });
    };

    const handleCreateIssueClick = () => {
        if (!selectedTemplate) {
            return;
        }

        form.validateFields(async (errors) => {
            if (
                has(errors, 'login') ||
                has(errors, 'password') ||
                keys(errors)?.some((key) => key.startsWith(variablePrefix))
            ) {
                return;
            }

            try {
                setIsCreationLoading(true);

                console.log(getIssueCreationBody());

                const result = await createIssueFromTemplate(selectedTemplate.id, getIssueCreationBody());
                appNotification.success({
                    message: 'Задача создана',
                    description: (
                        <div>
                            <p>{result.message}</p>
                            <p>
                                <a href={result.issueUrl} target="_blank" rel="noopener noreferrer">
                                    {result.issueUrl}
                                </a>
                            </p>
                        </div>
                    ),
                    duration: 0
                });
            } catch (error) {
                displayErrorNotification(getErrorMessage(error), 'Произошла ошибка при создании задачи');
            } finally {
                setIsCreationLoading(false);
            }
        });
    };

    const fetchVariablesInfo = async () => {
        try {
            setIsVariablesInfoLoading(true);
            await dispatch(getVariablesInfo());
        } catch (error) {
            setIsVariablesInfoModalVisible(false);
            displayErrorNotification(getErrorMessage(error), 'Произошла ошибка при получении справки по переменным');
        } finally {
            setIsVariablesInfoLoading(false);
        }
    };

    const handleVariablesInfoClick = async () => {
        if (!variablesInfo) {
            fetchVariablesInfo();
        }

        setIsVariablesInfoModalVisible(true);
    };

    const handleVariablesInfoModalOk = useCallback(() => {
        setIsVariablesInfoModalVisible(false);
    }, []);

    const variablesInfoModal = (
        <Modal
            title="Переменные шаблона"
            visible={isVariablesInfoModalVisible}
            onCancel={handleVariablesInfoModalOk}
            footer={null}
            centered
        >
            <Spin spinning={isVariablesInfoLoading}>
                {variablesInfo && (
                    <div className={styles.variablesInfoModalContent}>
                        <div>
                            <strong>Variable Regex:</strong> <code className="code">{variablesInfo.variableRegex}</code>
                        </div>
                        <div>
                            <strong>Variable Name Regex:</strong>{' '}
                            <code className="code">{variablesInfo.variableNameRegex}</code>
                        </div>
                        <div>
                            <div className={styles.variablesInfoModalDefaultVariablesHeading}>Default Variables:</div>
                            <div className={styles.variablesInfoModalDefaultVariablesList}>
                                {variablesInfo.defaultVariables.map((variable) => (
                                    <div>
                                        <Tag>{variable}</Tag>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            При использовании иных переменных необходимо указать их значения при создании задачи.
                            Default-переменные имеют значение по умолчанию, однако, их значение также может быть
                            изменено.
                        </div>
                    </div>
                )}
            </Spin>
        </Modal>
    );

    return (
        <AppPage title="Шаблоны задач">
            {variablesInfoModal}

            <Status loading={isLoading} error={error}>
                <Form
                    form={form}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: maxCol - labelCol }}
                    className={styles.form}
                >
                    <Form.Item label="Шаблон">
                        {form.getFieldDecorator('selectedTemplate')(
                            <IssueTemplateSelect
                                allowClear
                                placeholder="Новый шаблон"
                                onChange={handleSelectedTemplateChange}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Название">{form.getFieldDecorator('name')(<Input />)}</Form.Item>
                    <Form.Item label="Содержимое">
                        {form.getFieldDecorator('templateContent')(
                            <TextArea className={classNames('monospace', styles.templateContent)} rows={14} />
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: maxCol - labelCol, offset: labelCol }}>
                        <div className={styles.formButtonsContainer}>
                            <div className={styles.formButtonsSide}>
                                {selectedTemplate ? (
                                    <>
                                        <Button
                                            type="primary"
                                            loading={isSavingLoading}
                                            onClick={handleSaveTemplateClick}
                                        >
                                            Сохранить
                                        </Button>
                                        <Button
                                            type="danger"
                                            loading={isDeletionLoading}
                                            onClick={handleDeleteTemplateClick}
                                        >
                                            Удалить
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        type="primary"
                                        loading={isSavingLoading}
                                        onClick={handleSaveNewTemplateClick}
                                    >
                                        Сохранить новый
                                    </Button>
                                )}
                            </div>
                            <div className={styles.formButtonsSide}>
                                <Tooltip title="Справка по переменным" placement="bottomRight">
                                    <Button icon="question" onClick={handleVariablesInfoClick} />
                                </Tooltip>
                            </div>
                        </div>
                    </Form.Item>

                    {selectedTemplate?.variablesMap && Object.keys(selectedTemplate.variablesMap).length ? (
                        <>
                            <Form.Item wrapperCol={{ span: maxCol - labelCol, offset: labelCol }}>
                                <h3 className={styles.formHeading}>Переменные</h3>
                            </Form.Item>

                            {map(entries(selectedTemplate.variablesMap), ([key, value]) => (
                                <Form.Item label={key}>
                                    {form.getFieldDecorator(variablePrefix + key, {
                                        initialValue: value,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Укажите значение'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            ))}
                        </>
                    ) : null}

                    <Form.Item wrapperCol={{ span: maxCol - labelCol, offset: labelCol }}>
                        <h3 className={styles.formHeading}>Данные для входа</h3>
                    </Form.Item>

                    <Form.Item label="Логин">
                        {form.getFieldDecorator('login', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Введите логин'
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Пароль">
                        {form.getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Введите пароль'
                                }
                            ]
                        })(<Input type="password" />)}
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: maxCol - labelCol, offset: labelCol }}>
                        {(() => {
                            const button = (
                                <Button
                                    loading={isCreationLoading}
                                    disabled={!selectedTemplate}
                                    block
                                    type="primary"
                                    onClick={handleCreateIssueClick}
                                >
                                    Создать задачу
                                </Button>
                            );

                            return selectedTemplate ? (
                                button
                            ) : (
                                <Tooltip placement="bottom" title="Выберите шаблон">
                                    {button}
                                </Tooltip>
                            );
                        })()}
                    </Form.Item>
                </Form>
            </Status>
        </AppPage>
    );
};

const IssueTemplatePageForm = (Form.create()(IssueTemplatePage) as unknown) as FunctionComponent<Props>;

export default IssueTemplatePageForm;
