import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionCreator } from './helpers';
import {
    EmployeeReport,
    ProjectsReport,
    ReportByProject,
    ReportsList,
    UserWorkLog,
    UserWorkLogsReport
} from '../types/apiDomain';

export interface ReportsState {
    reportByEmployees?: EmployeeReport;
    reportByProjects?: ProjectsReport;
    reportByProject?: ReportByProject;
    reportsList?: ReportsList;
    reportById?: ReportByProject;
    userWorkLogsReport?: UserWorkLogsReport;
}

const initialState: ReportsState = {};

export const reportByEmployeesLoaded = actionCreator<{ report: EmployeeReport }>('REPORT_BY_EMPLOYEES');
export const reportByProjectsLoaded = actionCreator<{ report: ProjectsReport }>('REPORT_BY_PROJECTS');
export const reportByProjectLoaded = actionCreator<{ report: ReportByProject }>('REPORT_BY_PROJECT');
export const reportsListLoaded = actionCreator<{ report: ReportsList }>('REPORTS_LIST');
export const reportByIdLoaded = actionCreator<{ report: ReportByProject }>('REPORT_BY_ID');

export const userWorkLogsLoaded = actionCreator<{ workLogsReport: UserWorkLogsReport }>('USER_WORK_LOGS');
export const userWorkLogCreated = actionCreator<{ workLog: UserWorkLog }>('USER_WORK_LOG_CREATED');
export const userWorkLogEdited = actionCreator<{ workLog: UserWorkLog }>('USER_WORK_LOG_EDITED');
export const userWorkLogDeleted = actionCreator<{ issueId: string }>('USER_WORK_LOG_DELETED');
export const userWorkLogDeletedByQuery = actionCreator<{ workLog: UserWorkLog }>('USER_WORK_LOG_DELETED_BY_QUERY');

export default reducerWithInitialState(initialState)
    .case(reportByEmployeesLoaded, (state, { report }) => ({
        ...state,
        reportByEmployees: report
    }))
    .case(reportByProjectsLoaded, (state, { report }) => ({
        ...state,
        reportByProjects: report
    }))
    .case(reportByProjectLoaded, (state, { report }) => ({
        ...state,
        reportByProject: report
    }))
    .case(reportsListLoaded, (state, { report }) => ({
        ...state,
        reportsList: report
    }))
    .case(reportByIdLoaded, (state, { report }) => ({
        ...state,
        reportById: report
    }))
    .case(userWorkLogsLoaded, (state, { workLogsReport }) => ({
        ...state,
        userWorkLogsReport: workLogsReport
    }))
    .case(userWorkLogCreated, ({ userWorkLogsReport, ...state }, { workLog }) => ({
        ...state,
        userWorkLogsReport: userWorkLogsReport
            ? {
                  ...userWorkLogsReport,
                  workLogs: [workLog, ...userWorkLogsReport.workLogs]
              }
            : {
                  workLogs: [workLog]
              }
    }))
    .case(userWorkLogEdited, ({ userWorkLogsReport, ...state }, { workLog }) => ({
        ...state,
        userWorkLogsReport: userWorkLogsReport
            ? {
                  ...userWorkLogsReport,
                  workLogs: userWorkLogsReport.workLogs?.map((value) =>
                      value.issueId === workLog.issueId ? workLog : value
                  )
              }
            : undefined
    }))
    .case(userWorkLogDeleted, ({ userWorkLogsReport, ...state }, { issueId }) => ({
        ...state,
        userWorkLogsReport: userWorkLogsReport
            ? {
                  ...userWorkLogsReport,
                  workLogs: userWorkLogsReport.workLogs?.filter((value) => value.issueId !== issueId)
              }
            : undefined
    }))
    .case(userWorkLogDeletedByQuery, ({ userWorkLogsReport, ...state }, { workLog }) => ({
        ...state,
        userWorkLogsReport: userWorkLogsReport
            ? {
                  ...userWorkLogsReport,
                  workLogs: userWorkLogsReport.workLogs?.filter((value) => value !== workLog)
              }
            : undefined
    }));
