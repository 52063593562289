import React, { FunctionComponent } from 'react';
import getReportByEmployees from '../../actions/api/reports/getReportByEmployees';
import ReportByEmployeesTable from '../../components/reportByEmployeesTable/ReportByEmployeesTable';
import ReportByEmployeesCalendar from '../../components/reportByEmployeesCalendar/ReportByEmployeesCalendar';
import EmployeeXlsExport from '../../components/xlsReport/EmployeeXlsExport';
import { useAppSelector } from '../../hooks/reactRedux';
import ReportPageTemplate from '../../components/reportPageTemplate/ReportPageTemplate';
import { EmployeeReport } from '../../types/apiDomain';
import { Collapse, Descriptions } from 'antd';
import styles from './ReportByEmployeesPage.module.scss';

const countValueExtractor = (report: EmployeeReport) => report.employees.length;
const exportNodeGetter = (report: EmployeeReport) => <EmployeeXlsExport tableData={report} />;
const tableGetter = (report: EmployeeReport) => <ReportByEmployeesTable tableData={report} />;
const renderAfterMainPanel = (report?: EmployeeReport) => (
    <Collapse.Panel header="Календарь" key="calendar" className={styles.calendarTab}>
        <div className={styles.tableWrapper}>
            <ReportByEmployeesCalendar tableData={report} />
        </div>
    </Collapse.Panel>
);
const renderExtraReportInfo = (report?: EmployeeReport) => (
    <>
        <Descriptions.Item label="Other&Education&Downtime projects">
            {report?.reportInfoPanelStats?.otherEduDowntimeHours} (
            {report?.reportInfoPanelStats?.otherEduDowntimeHoursPercent}%)
        </Descriptions.Item>
        <Descriptions.Item label="Other">
            {report?.reportInfoPanelStats?.otherHours} ({report?.reportInfoPanelStats?.otherHoursPercent}%)
        </Descriptions.Item>
        <Descriptions.Item label="Education">
            {report?.reportInfoPanelStats?.educationHours} ({report?.reportInfoPanelStats?.educationHoursPercent}%)
        </Descriptions.Item>
        <Descriptions.Item label="Downtime">
            {report?.reportInfoPanelStats?.downtimeHours} ({report?.reportInfoPanelStats?.downtimeHoursPercent}%)
        </Descriptions.Item>
    </>
);

const ReportByEmployeesPage: FunctionComponent = () => {
    const report = useAppSelector((state) => state.report.reportByEmployees);

    return (
        <ReportPageTemplate
            title="Отчет по сотрудникам"
            report={report}
            fetchReport={getReportByEmployees}
            countLabel="employees"
            countValueExtractor={countValueExtractor}
            exportNodeGetter={exportNodeGetter}
            tableGetter={tableGetter}
            renderAfterMainPanel={renderAfterMainPanel}
            renderExtraReportInfo={renderExtraReportInfo}
        />
    );
};

export default ReportByEmployeesPage;
