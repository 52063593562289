import React, { ComponentType, PureComponent } from 'react';
import { Button, Form, Tabs, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/rootReducer';

const { Option } = Select;
const { TabPane } = Tabs;

interface Props {
    submitForm(formFields: object): Promise<any>;
}

interface InternalProps extends Props, FormComponentProps {
    sourceList: string[];
}

interface State {
    hasError?: boolean;
    isLoading?: boolean;
}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class ImportTasksForm extends PureComponent<InternalProps, State> {
    state: State = {};

    handleSubmit = (e) => {
        const { submitForm } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', fieldsValue);
            this.setState({ isLoading: true });

            submitForm(fieldsValue).finally(() => {
                this.setState({ isLoading: false });
            });
        });
    };

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const { sourceList } = this.props;
        const { isLoading } = this.state;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 6 }
        };

        const buttonItemLayout = {
            wrapperCol: { span: 6, offset: 6 }
        };

        return (
            <Tabs type="card">
                <TabPane tab="Принудительный импорт задач" key="1">
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        {sourceList && (
                            <Form.Item label={<span>Источник</span>}>
                                {getFieldDecorator('source')(
                                    <Select>
                                        {sourceList.map((item, index) => {
                                            return (
                                                <Option value={item} key={index}>
                                                    {item}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        )}

                        <Form.Item {...buttonItemLayout}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={hasErrors(getFieldsError())}
                                loading={isLoading}
                            >
                                Импорт
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
        );
    }
}

const ImportTasks = Form.create<InternalProps>({ name: 'importTasks' })(ImportTasksForm);

const mapState = (state: AppState) => {
    return {
        sourceList: state.dictionaries.sourceList
    };
};

export default (connect(mapState, null)(ImportTasks) as unknown) as ComponentType<Props>;
