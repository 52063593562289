export interface QualityMarkers {
    TAGGED_WITH_RESULT: number;
    ESTIMATED: number;
}

export interface IssueStats {
    id: string;
    desc: string;
    url: string;
    amount: number;
    employeeRate: number;
    projectSum: number;
    employeeSum: number;
    margin: number;
    marginPercent: number;
    qualityMarkers: string[];
    worklogs: WorkLog[];
}

export interface ProjectStats {
    id: string;
    amount: number;
    employeeRate: number;
    projectSum: number;
    employeeSum: number;
    margin: number;
    marginPercent: number;
    qualityMarkers: QualityMarkers;
    issues: IssueStats[];
}

export interface EmployeeStats {
    name: string;
    firstName: string;
    lastName: string;
    amount: number;
    employeeRate: number;
    projectSum: number;
    employeeSum: number;
    margin: number;
    marginPercent: number;
    qualityIndex: number;
    status: string;
    projects: ProjectStats[];
}

export interface ReportByEmployees {
    id: string;
    startDate: string;
    endDate: string;
    amount: number;
    employeeSum: number;
    margin: number;
    marginPercent: number;
    employees?: EmployeeStats[];
    projects: string[];
    workLogs?: WorkLog[];
    status: string;
}

export interface TableRow {
    name: string;
    firstName: string;
    lastName: string;
    amount: string;
    projectId: string;
    issueId: string;
    issueDesc: string;
    issueUrl: string;
    issueAmount: string;
    issueMarkers: string;
    workLogHours: string;
    workLogDate: string;
    workLogComment: string;
}

export interface ReportByProject {
    id: string;
    number: string;
    project: string;
    rate: number;
    activity: string;
    date: string;
    dateStart: string;
    dateEnd: string;
    amount: number;
    total: number;
    fakeAmount: number;
    fakeTotal: number;
    totalInWords: string;
    items?: ReportByProjectItem[];
    files?: string[];
}

export interface ReportByProjectItem {
    id: string;
    num: number;
    desc: string;
    url: string;
    amount: number;
    sum: number;
    fakeAmount: number;
    fakeSum: number;
    price: number;
    fake: boolean;
}

export interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
    sort: PageableSort;
}

export interface PageableSort {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
}

export interface ReportsList {
    content: ReportByProject[];
    pageable: Pageable;
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: PageableSort;
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}

export interface User {
    aliases: string[];
    birthday: string;
    employed: boolean;
    firstName: string;
    lastName: string;
    position: string;
    secondName: string;
    username: string;
}

export interface WorkLog {
    author: string;
    comment: string;
    date: string;
    minutes: number;
}

export interface ExportLogs {
    uuid: string;
    date: string;
    responseCode: number;
    responseData: any;
    sentData: SendData;
}

interface SendData {
    callbackUrl: string;
    items: SendDataItem[];
}

interface SendDataItem {
    deleted: boolean;
    desc: string;
    id: string;
    project: string;
    updatedAt: string;
    url: string;
    workLogs: ExportWorkLog[];
}

interface ExportWorkLog {
    comment: string;
    date: string;
    employee: string;
    hours: number;
}

export interface ExportLogsCallback {
    date: string;
    uuid: string;
    data: ExportLogsData[];
}

interface ExportLogsData {
    acceptedHours: number;
    receivedHours: number;
    logs: ExportCallbackLog[];
}

export interface ExportCallbackLog {
    errorCode: number;
    errorText: string;
    id: string;
}

export type IssueShort = Pick<IssueStats, 'id' | 'desc' | 'url'>;

export interface WorkLogDto {
    author: string;
    minutes: number;
    date: string;
    comment: string;
    isInternal: boolean;
    isEditable: boolean;
    isFromCurrentImportRange: boolean;
}

export interface UserWorkLog extends WorkLogDto {
    project: string;
    issueId: string;
    issueUrl: string | null;
    displayableIssueId: string;
    issueName: string | null;
    externalId: string;
}

export interface HoursReport {
    approximateHoursForPeriod: number;
    totalHoursForPeriodInternal: number;
    totalHoursForPeriodExternal: number;
    totalHoursForPeriod: number;
}

// Returned from /api/report/worklogs
export interface UserWorkLogsReport {
    workLogs: UserWorkLog[];
    hoursReport?: HoursReport;
    projectsByCustomers?: ProjectsByCustomersReport;
    holidays?: string[];
}

export interface UserWorkLogEdit {
    minutes: number;
    date: string;
    comment: string;
    project: string;
    externalId: string;
}

export enum UserRole {
    USER = 'users',
    ADMIN = 'admin',
    DASH_ADMIN = 'dash-admin'
}

// Returned from /api/auth/account
export interface UserInfo {
    id: string;
    email: string;
    roles: UserRole[];
}

export interface ContractedProject {
    project: string;
    customer: string;
}

// Returned from /api/lookup/projects-by-customers
export interface ProjectsByCustomersReport {
    projectsByCustomers: ProjectsByCustomers;
    projects: ContractedProject[];
    customers: string[];
}

export type ProjectsByCustomers = Record<string, string[]>;

export interface Report {
    startDate: string;
    endDate: string;
    amount: number;
    hoursForPeriod: number;
    projectsByCustomers: ProjectsByCustomersReport;
    maxTotalHoursForPeriod: number;
    holidays: string[];
}

export interface ReportInfoPanelStats {
    downtimeHours: number;
    downtimeHoursPercent: number;
    educationHours: number;
    educationHoursPercent: number;
    otherEduDowntimeHours: number;
    otherEduDowntimeHoursPercent: number;
    otherHours: number;
    otherHoursPercent: number;
}

export interface EmployeeReport extends Report {
    employees: EmployeeStats[];
    reportInfoPanelStats: ReportInfoPanelStats;
}

export interface ProjectsReport extends Report {
    customers: CustomerStats[];
    projectsInvoice: string;
}

interface Stats {
    amount: number;
}

export interface CustomerStats extends Stats {
    id: string;
    projects: CustomerProjectStats[];
}

export interface CustomerProjectStats extends Stats {
    id: string;
    issues: ProjectIssueStats[];
}

export interface ProjectIssueStats extends Stats {
    id: string;
    employees: IssueEmployeeStats[];
}

export interface IssueEmployeeStats extends Stats {
    name: string;
    firstName: string;
    lastName: string;
    worklogs: WorkLogDto[];
}

export interface WorkLogQuery {
    issueId: string;
    author: string;
    minutes: number;
    date: string;
    comment: string;
}

export interface IssueTemplateEntity {
    id: string;
    service: string;
    name?: string;
    template: string;
    variables: string[];
    variablesMap: Record<string, string>;
    filledVariables: string[] | null;
    variablesToFill: string[] | null;
}

export interface IssueTemplateEntityRaw extends Omit<IssueTemplateEntity, 'template'> {
    template: unknown;
}

export interface IssueTemplateEdit {
    name?: string;
    template: unknown;
}

export interface IssueCreationResult {
    issueUrl: string;
    message: string;
    response: IssueCreationResultResponse;
}

export interface IssueCreationResultResponse {
    creation?: IssueCreationResultResponseCreation;
    edit?: string;
    transitions?: unknown[];
    worklogs?: unknown[];
}

export interface IssueCreationResultResponseCreation {
    id: string;
    key: string;
    self: string;
}

export interface Credentials {
    login: string;
    password: string;
}

export interface IssueTemplateVariablesInfo {
    variableNameRegex: string;
    variableRegex: string;
    defaultVariables: string[];
}

export interface FilledTemplateRequest {
    variables: Record<string, string>;
}

export interface CreateIssueByTemplateRequest extends FilledTemplateRequest {
    credentials: Credentials;
}

export interface PasswordResetRequest {
    login: string;
    email: string;
}
