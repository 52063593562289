import React, { useEffect, useRef } from 'react';
import ReportPageTemplate from '../../components/reportPageTemplate/ReportPageTemplate';
import { ProjectsReport } from '../../types/apiDomain';
import { useAppSelector } from '../../hooks/reactRedux';
import ProjectsReportXlsExport from '../../components/projectsReportXlsExport/ProjectsReportXlsExport';
import ReportByProjectsTable from './components/reportByProjectsTable/ReportByProjectsTable';
import getReportByProjects from '../../actions/api/reports/getReportByProjects';
import { Collapse, Typography } from 'antd';
import { isNil } from 'lodash';
import Text from 'antd/lib/typography/Text';

const countValueExtractor = (report: ProjectsReport) =>
    report.customers.reduce((result, customer) => result + customer.projects.length, 0);
const exportNodeGetter = (report: ProjectsReport) => <ProjectsReportXlsExport tableData={report} />;
const tableGetter = (report: ProjectsReport) => <ReportByProjectsTable tableData={report} />;
const renderAfterInfoPanel = (report?: ProjectsReport) => (
    <Collapse.Panel
        header={
            <Typography.Paragraph copyable={{ text: report?.projectsInvoice }} className="p-0 mb-0 invoice-header">
                Инвойс
            </Typography.Paragraph>
        }
        key="invoice"
    >
        <Typography.Paragraph copyable={Boolean(report?.projectsInvoice)} className="text-pre-wrap">
            {report?.projectsInvoice || <Text type="secondary">Пусто</Text>}
        </Typography.Paragraph>
    </Collapse.Panel>
);

const ReportByProjectsPage = () => {
    const report = useAppSelector((state) => state.report.reportByProjects);

    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = elementRef.current;

        if (!element) {
            return;
        }

        const invoiceCopyElement = element.querySelector<HTMLDivElement>('.invoice-header .ant-typography-copy');

        if (!invoiceCopyElement) {
            console.warn('ReportByProjectsPage: Unable to get invoiceCopyElement');
            return;
        }

        const clickHandler = (event) => {
            event.stopPropagation();

            if (report && !isNil(report.projectsInvoice)) {
                navigator.clipboard.writeText(report.projectsInvoice);
            }
        };

        invoiceCopyElement.addEventListener('click', clickHandler, { capture: true });

        return () => {
            invoiceCopyElement.removeEventListener('click', clickHandler);
        };
    }, [report]);

    return (
        <ReportPageTemplate
            ref={elementRef}
            title="Отчет по проектам"
            report={report}
            fetchReport={getReportByProjects}
            countLabel="projects"
            countValueExtractor={countValueExtractor}
            exportNodeGetter={exportNodeGetter}
            tableGetter={tableGetter}
            renderAfterInfoPanel={renderAfterInfoPanel}
        />
    );
};

export default ReportByProjectsPage;
